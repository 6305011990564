<template>
  <VList
    :hide-header="true"
    :rows="displayedRows"
    :headers="headers"
    display-pagination
    :is-loading="isLoading"
    :pagination="pagination"
    @update:current_page="onUpdateCurrentPage"
    @update:per_page="onUpdatePerPage"
  >
    <!-- eslint-disable-next-line -->
    <template #item.image="{ item }">
      <VImage :src="item?.displayed_avatar" />
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.name="{ item }">
      <div class="cursor-pointer" @click="onClickUpdate(item.id)">
        <div class="font-medium whitespace-nowrap">
          {{ item?.displayed_name }}
        </div>

        <div class="text-xs font-light">
          {{ item?.displayed_username }}
        </div>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.collection="{ item }">
      <div>
        <div class="font-medium whitespace-nowrap">
          {{ item?.displayed_collection }}
        </div>

        <div class="version mt-0.5">
          {{ $t("app.versions") }} {{ item?.collection?.version }}
        </div>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.registered_on="{ item }">
      {{ formatDate({ date: item?.registered_on }) }}
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.result="{ item }">
      <div class="flex justify-center">
        <VChip
          :text="$t(`app.${item?.result}`)"
          :class="getStatusColor(item?.result)"
        />
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <div class="flex justify-center">
        <VChip
          :text="item?.displayed_status"
          :class="getStatusColor(item?.status)"
        />
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.__ACTIONS="{ item }">
      <div class="flex justify-center">
        <VAction
          :label="$t('app.edit')"
          icon="edit"
          @click="onClickUpdateDetails(item?.id)"
        />

        <VDeleteAction
          :item="item"
          :text-value="item?.displayed_name"
          :delete-function="onClickDelete"
        />
      </div>
    </template>
  </VList>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
import useColor from "@/composables/useColor";
import useRegistrationCollection from "@/composables/useRegistrationCollection";
import useRead from "@/composables/useRead";
import useDisplay from "@/composables/useDisplay";
// Components
import VList from "@/components/tables/VList";
import VAction from "@/components/tables/VAction";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";
import VDeleteAction from "@/components/VDeleteAction";

export default {
  components: {
    VList,
    VAction,
    VImage,
    VChip,
    VDeleteAction
  },
  setup() {
    // MISC
    const { t } = useI18n();

    // CONSTANTS
    const documentTitle = `${t("app.registrations", 2)} - ${t("app.teaching")}`;

    // Computed
    const displayedRows = computed(() => {
      return rows.value.map(row => ({
        ...row,
        displayed_avatar: getAvatarURL(row?.learner?.avatar?.view_path),
        displayed_name: `${row?.learner?.firstname} ${row?.learner?.lastname}`,
        displayed_username: `@${row?.learner?.username}`,
        displayed_status: t(`app.${row?.status}`),
        displayed_collection: getText(row?.collection?.texts, "name")
      }));
    });

    // CUSTOM COMPOSABLES
    const { getText } = useTexts();
    const { getAvatarURL, formatDate } = useDisplay();
    const { getStatusColor } = useColor();
    const {
      endpoint,
      route,
      types,
      searchFields,
      headers
    } = useRegistrationCollection();
    const {
      pagination,
      rows,
      isLoading,
      onClickUpdate,
      onUpdateCurrentPage,
      onUpdatePerPage,
      onClickDelete,
      onClickUpdateDetails,
      getData
    } = useRead({
      endpoint,
      route,
      relations: ["learner", "collection"],
      types,
      searchFields
    });

    return {
      documentTitle,
      displayedRows,
      // useDisplay
      formatDate,
      // useRegistrationCollection
      headers,
      // useColor
      getStatusColor,
      // useRead
      pagination,
      onUpdateCurrentPage,
      onUpdatePerPage,
      onClickDelete,
      rows,
      isLoading,
      onClickUpdate,
      onClickUpdateDetails,
      getData
    };
  }
};
</script>
